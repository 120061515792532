var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " <button class=\"previous-image-btn\" aria-label=\"Previous image\"></button> ";
},"3":function(container,depth0,helpers,partials,data) {
    return " <button class=\"next-image-btn\" aria-label=\"Next image\"></button> ";
},"5":function(container,depth0,helpers,partials,data) {
    return " Open image ";
},"7":function(container,depth0,helpers,partials,data) {
    return " Visit page ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<button class=\"close-btn\" aria-label=\"Close\"></button> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasPreviousImage : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasNextImage : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <a class=\"image-link\" href=\""
    + alias3(alias2((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\"><img src=\""
    + alias3(alias2((depth0 != null ? depth0.image : depth0), depth0))
    + "\" decoding=\"async\" alt=\"\"> </a><a class=\"btn open-btn\" href=\""
    + alias3(alias2((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\"> "
    + ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"image",{"name":"equal","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " </a>";
},"useData":true});