var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "username to <b>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.new_ident : depth0), depth0))
    + "</b>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.new_host : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return ", and";
},"4":function(container,depth0,helpers,partials,data) {
    return "hostname to <i class=\"hostmask\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.new_host : depth0), depth0))
    + "</i>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = container.invokePartial(require("/home/travis/build/thelounge/thelounge/client/views/user_name.tpl"),depth0,{"name":"../user_name","hash":{"mode":((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.mode : stack1),"nick":((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.nick : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " has changed "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.new_ident : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.new_host : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});