var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " <h1 class=\"title\">Release notes for "
    + container.escapeExpression(((helper = (helper = helpers.version || (depth0 != null ? depth0.version : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"version","hash":{},"data":data}) : helper)))
    + "</h1> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.changelog : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return " <h3>Introduction</h3><div class=\"changelog-text\">"
    + ((stack1 = ((helper = (helper = helpers.changelog || (depth0 != null ? depth0.changelog : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"changelog","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div> ";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return " <p>Unable to retrieve releases from GitHub.</p><p><a href=\"https://github.com/thelounge/thelounge/releases/tag/v"
    + container.escapeExpression(((helper = (helper = helpers.version || (depth0 != null ? depth0.version : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"version","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" rel=\"noopener\">View release notes for this version on GitHub</a></p> ";
},"6":function(container,depth0,helpers,partials,data) {
    return " <p>Loading changelog…</p> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"header\"><button class=\"lt\" aria-label=\"Toggle channel list\"></button></div><div class=\"container\"><a href=\"#\" id=\"back-to-help\" data-target=\"#help\">« Help</a> "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.version : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + " </div>";
},"useData":true});