var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " <p>Checking for updates...</p> ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/equal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.status : depth0),"new-version",{"name":"equal","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " <p>The Lounge <b>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.latest : depth0)) != null ? stack1.version : stack1), depth0))
    + "</b>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.latest : depth0)) != null ? stack1.prerelease : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " is now available.<br><a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.latest : depth0)) != null ? stack1.url : stack1), depth0))
    + "\" target=\"_blank\" rel=\"noopener\">Read more on GitHub</a></p> ";
},"5":function(container,depth0,helpers,partials,data) {
    return " (pre-release)";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/equal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.status : depth0),"up-to-date",{"name":"equal","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return " <p>The Lounge is up to date!</p><button id=\"check-now\" class=\"btn btn-small\">Check now</button> ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/equal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.status : depth0),"error",{"name":"equal","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return " <p>Information about latest releases could not be retrieved.</p><button id=\"check-now\" class=\"btn btn-small\">Try again</button> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/equal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.status : depth0),"loading",{"name":"equal","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});