var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " self";
},"3":function(container,depth0,helpers,partials,data) {
    return " highlight";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " data-from=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.nick : stack1), depth0))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("/home/travis/build/thelounge/thelounge/client/views/user_name.tpl"),(depth0 != null ? depth0.from : depth0),{"name":"user_name","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    return " <div class=\"preview\" data-url=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.link : depth0), depth0))
    + "\"></div> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"msg "
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.self : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.highlight : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"msg-"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" data-time=\""
    + alias2(alias1((depth0 != null ? depth0.time : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.nick : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span class=\"time tooltipped tooltipped-e\" aria-label=\""
    + alias2(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/localetime.js")).call(alias3,(depth0 != null ? depth0.time : depth0),{"name":"localetime","hash":{},"data":data}))
    + "\"> "
    + alias2(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/tz.js")).call(alias3,(depth0 != null ? depth0.time : depth0),{"name":"tz","hash":{},"data":data}))
    + " </span><span class=\"from\"> "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.nick : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </span><span class=\"content\"><span class=\"text\">"
    + ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/parse.js")).call(alias3,(depth0 != null ? depth0.text : depth0),(depth0 != null ? depth0.users : depth0),{"name":"parse","hash":{},"data":data})) != null ? stack1 : "")
    + "</span> "
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.previews : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </span></div>";
},"usePartial":true,"useData":true});