var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "<div class=\"msg condensed closed\" data-time=\""
    + alias1(container.lambda((depth0 != null ? depth0.time : depth0), depth0))
    + "\"><div class=\"condensed-summary\"><span class=\"time\">"
    + alias1(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/tz.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.time : depth0),{"name":"tz","hash":{},"data":data}))
    + "</span><span class=\"from\"></span> <span class=\"content\"></span></div></div>";
},"useData":true});