var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " <tr><td class=\"hostmask\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.hostmask : depth0), depth0))
    + "</td><td class=\"banned_by\">"
    + ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/parse.js")).call(alias1,(depth0 != null ? depth0.banned_by : depth0),{"name":"parse","hash":{},"data":data})) != null ? stack1 : "")
    + "</td><td class=\"banned_at\">"
    + ((stack1 = __default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/localetime.js")).call(alias1,(depth0 != null ? depth0.banned_at : depth0),{"name":"localetime","hash":{},"data":data})) != null ? stack1 : "")
    + "</td></tr> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table class=\"ban-list\"><thead><tr><th class=\"hostmask\">Banned</th><th class=\"banned_by\">Banned By</th><th class=\"banned_at\">Banned At</th></tr></thead><tbody> "
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.bans : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </tbody></table>";
},"useData":true});