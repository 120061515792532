var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " data-token=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.token : depth0), depth0))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return " Sign out ";
},"5":function(container,depth0,helpers,partials,data) {
    return " Revoke ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " <br> "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
},"8":function(container,depth0,helpers,partials,data) {
    return " <em>Currently active</em> ";
},"10":function(container,depth0,helpers,partials,data) {
    return " Last used on <time>"
    + container.escapeExpression(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/localetime.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.lastUse : depth0),{"name":"localetime","hash":{},"data":data}))
    + "</time> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<p><button class=\"btn pull-right remove-session\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.current : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " > "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.current : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + " </button> <strong>"
    + alias3(alias2((depth0 != null ? depth0.agent : depth0), depth0))
    + "</strong><a href=\"https://ipinfo.io/"
    + alias3(alias2((depth0 != null ? depth0.ip : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener\">"
    + alias3(alias2((depth0 != null ? depth0.ip : depth0), depth0))
    + "</a> "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.current : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </p>";
},"useData":true});