var Handlebars = require("/home/travis/build/thelounge/thelounge/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.lambda;

  return "<span role=\"button\" class=\"user "
    + alias1(__default(require("/home/travis/build/thelounge/thelounge/client/js/libs/handlebars/colorClass.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nick : depth0),{"name":"colorClass","hash":{},"data":data}))
    + "\" data-name=\""
    + alias1(alias2((depth0 != null ? depth0.nick : depth0), depth0))
    + "\">"
    + alias1(alias2((depth0 != null ? depth0.mode : depth0), depth0))
    + alias1(alias2((depth0 != null ? depth0.nick : depth0), depth0))
    + "</span>";
},"useData":true});